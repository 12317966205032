<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Position Details</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <b-row class="mt-1">
      <b-col cols="6">
        <b-form-group
          label="Job Title"
          label-cols-md="4"
          label-align="right"
        >
          <span
            class="form-control"
            v-text="job.job_title"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Location"
          label-cols-md="4"
          label-align="right"
        >
          <span
            class="form-control"
            v-text="job.location"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Position Type"
          label-cols-md="4"
          label-align="right"
        >
          <span
            class="form-control"
            v-text="job.position_type"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Visa"
          label-cols-md="4"
          label-align="right"
        >
          <span
            class="form-control"
            v-text="job.visa_types"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Status"
          label-cols-md="4"
          label-align="right"
        >
          <span
            class="form-control"
            v-text="job.status"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Skills"
          label-cols-md="4"
          label-align="right"
        >
          <span
            class="form-control"
            v-text="job.skill_names"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Experience"
          label-cols-md="4"
          label-align="right"
        >
          <b-input-group append="Year's">
            <span
              class="form-control"
              v-text="job.experience_years"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Duration"
          label-cols-md="4"
          label-align="right"
        >
          <span
            class="form-control"
            v-text="job.duration"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Immediate"
          label-cols-md="4"
          label-align="right"
        >
          <span
            class="form-control"
            v-text="job.immediate_need ? 'Yes' : 'No'"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label="Description"
          label-cols-md="2"
          label-align="right"
        >
          <span
            class="form-control field-height-auto quill-view"
            v-html="job.description"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <div class="d-flex mt-2">
        <h4 class="mb-0 ml-50">
          Technologies
        </h4>
      </div>
      <b-col
        cols="10"
        offset="2"
      >
        <b-row>
          <b-col
            v-for="technology in job.job_technologys"
            :key="technology"
            :value="task"
            cols="3"
          >
            <b-badge
              variant="primary"
              class="mr-1"
            >
              <span>{{ technology.name }}</span>
            </b-badge>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div
      v-if="job.job_technologys.length == 0"
      class="text-center"
    >
      <p> No Technologies available </p>
    </div>
    <hr>

    <b-row class="ml-2">
      <b-col class="text-right">
        <router-link
          :to="{ name: 'refer-candidates-new', params: { job_id: jobId}, query: {name: title}}"
          class="btn btn-primary"
        >
          Apply / Refer
        </router-link>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BInputGroup, BButton, BBadge,
} from 'bootstrap-vue'
import User from '@/js/user'
import Job from '@/js/jobs'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BButton,
    BBadge,
  },
  directives: {
  },
  data() {
    return {
      job: {},
      jobId: null,
      title: '',
      type: '',
    }
  },
  mounted() {
  },
  created() {
    this.type = this.$route.query.type
    if (!User.profile().job_exist) {
      if (User.profile().invoice_exist) {
        window.location.hash = '#/invoices'
      } else if (User.profile().timesheet_exist) {
        window.location.hash = '#/timesheets'
      } else {
        // Auth.logout()
      }
      return
    }
    this.jobId = this.$route.params.job_id
    this.title = this.$route.query.name
    Job.getJobById(this, this.$route.params.job_id, this.type)
  },
  destroyed() {
  },
  methods: {
    setJob(job) {
      this.job = job
      this.job.skill_names = job.skill_names.join(', ')
      this.job.visa_types = job.visa_type_names.join(', ')
    },
    back() {
      this.$router.push('/jobs').catch(() => {})
    },
  },
  setup() {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
